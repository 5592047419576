var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: _vm.idTable + "_container " } },
    [
      _c(
        "div",
        {
          staticClass: "table_scroll_duplicate",
          attrs: { id: _vm.idTable + "_scrollDuplicate" },
        },
        [_c("div", { attrs: { id: _vm.idTable + "_dummy" } })]
      ),
      _c(
        "v-data-table",
        {
          attrs: {
            id: _vm.idTable,
            loading: _vm.loadingTable,
            headers: _vm.headers,
            options: _vm.pagination,
            items: _vm.items,
            page: _vm.pagination.page,
            "server-items-length": _vm.totalItems,
            "footer-props": {
              disablePagination: _vm.items.length === 0 || _vm.loadingTable,
              itemsPerPageOptions:
                _vm.totalItems < 50
                  ? [
                      { value: 50, text: "50" },
                      { value: 100, text: "100", disabled: true },
                      { value: 250, text: "250", disabled: true },
                    ]
                  : _vm.totalItems < 100
                  ? [
                      { value: 50, text: "50" },
                      { value: 100, text: "100" },
                      { value: 250, text: "250", disabled: true },
                    ]
                  : [
                      { value: 50, text: "50" },
                      { value: 100, text: "100" },
                      { value: 250, text: "250" },
                    ],
              disableItemsPerPage: _vm.items.length === 0 || _vm.loadingTable,
              itemsPerPageText: `${_vm.tableTitle} por página: `,
              expandIcon: "mdi-dots-horizontal",
            },
            "no-data-text": `Não há registros de ${_vm.tableTitle} a serem exibidos.`,
          },
          on: {
            "update:options": function ($event) {
              _vm.pagination = $event
            },
            "current-items": _vm.onTableInput,
          },
          scopedSlots: _vm._u([
            {
              key: "footer.page-text",
              fn: function (items) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm.loadingTable ? 0 : items.pageStart) +
                      " - " +
                      _vm._s(_vm.loadingTable ? 0 : items.pageStop) +
                      " de " +
                      _vm._s(_vm.loadingTable ? 0 : items.itemsLength) +
                      " " +
                      _vm._s(_vm.tableTitle) +
                      " "
                  ),
                ]
              },
            },
            {
              key: "no-data",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-center",
                      style: _vm.getTableWidth,
                      attrs: { id: "noDataText" },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          "Não há registros de " +
                            _vm._s(_vm.tableTitle) +
                            " a serem exibidos."
                        ),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "item.occupation",
              fn: function ({ item }) {
                return [
                  _c(
                    "AGTooltip",
                    { attrs: { "tooltip-text": item.occupation } },
                    [
                      [
                        _c(
                          "div",
                          { staticClass: "cbo-classification-ocupation" },
                          [
                            _c(
                              "div",
                              { staticClass: "text-no-wrap text-truncate" },
                              [_vm._v(" " + _vm._s(item.occupation) + " ")]
                            ),
                          ]
                        ),
                      ],
                    ],
                    2
                  ),
                ]
              },
            },
            {
              key: "item.family",
              fn: function ({ item }) {
                return [
                  _c(
                    "AGTooltip",
                    { attrs: { "tooltip-text": item.family } },
                    [
                      [
                        _c(
                          "div",
                          { staticClass: "cbo-classification-family" },
                          [
                            _c(
                              "div",
                              { staticClass: "text-no-wrap text-truncate" },
                              [_vm._v(" " + _vm._s(item.family) + " ")]
                            ),
                          ]
                        ),
                      ],
                    ],
                    2
                  ),
                ]
              },
            },
            {
              key: "item.synonyms",
              fn: function ({ item }) {
                return [
                  _c(
                    "AGTooltip",
                    { attrs: { "tooltip-text": item.synonyms } },
                    [
                      [
                        _c(
                          "div",
                          { staticClass: "cbo-classification-synonyms" },
                          [
                            _c(
                              "div",
                              { staticClass: "text-no-wrap text-truncate" },
                              [_vm._v(" " + _vm._s(item.synonyms) + " ")]
                            ),
                          ]
                        ),
                      ],
                    ],
                    2
                  ),
                ]
              },
            },
            {
              key: "item.summaryDescription",
              fn: function ({ item }) {
                return [
                  _c(
                    "AGTooltip",
                    { attrs: { "tooltip-text": item.summaryDescription } },
                    [
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "cbo-classification-summaryDescription",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "text-no-wrap text-truncate" },
                              [
                                _vm._v(
                                  " " + _vm._s(item.summaryDescription) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                    ],
                    2
                  ),
                ]
              },
            },
            {
              key: "item.regulation",
              fn: function ({ item }) {
                return [
                  _c(
                    "AGTooltip",
                    { attrs: { "tooltip-text": item.regulation } },
                    [
                      [
                        _c(
                          "div",
                          { staticClass: "cbo-classification-regulation" },
                          [
                            _c(
                              "div",
                              { staticClass: "text-no-wrap text-truncate" },
                              [_vm._v(" " + _vm._s(item.regulation) + " ")]
                            ),
                          ]
                        ),
                      ],
                    ],
                    2
                  ),
                ]
              },
            },
            {
              key: "item.updated",
              fn: function ({ item }) {
                return [
                  _c("div", { staticClass: "cbo-classification-updated" }, [
                    _c("div", { staticClass: "text-no-wrap text-truncate" }, [
                      _vm._v(" " + _vm._s(item.updated) + " "),
                    ]),
                  ]),
                ]
              },
            },
            {
              key: "item.professionalRegulatoryBody",
              fn: function ({ item }) {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "cbo-classification-professionalRegulatoryBody",
                    },
                    [
                      _c("div", { staticClass: "text-no-wrap text-truncate" }, [
                        _vm._v(
                          " " + _vm._s(item.professionalRegulatoryBody) + " "
                        ),
                      ]),
                    ]
                  ),
                ]
              },
            },
            {
              key: "item.workingConditions",
              fn: function ({ item }) {
                return [
                  _c(
                    "AGTooltip",
                    { attrs: { "tooltip-text": item.workingConditions } },
                    [
                      [
                        _c(
                          "div",
                          {
                            staticClass: "cbo-classification-workingConditions",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "text-no-wrap text-truncate" },
                              [
                                _vm._v(
                                  " " + _vm._s(item.workingConditions) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                    ],
                    2
                  ),
                ]
              },
            },
            {
              key: "item.formationExperience",
              fn: function ({ item }) {
                return [
                  _c(
                    "AGTooltip",
                    { attrs: { "tooltip-text": item.formationExperience } },
                    [
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "cbo-classification-formationExperience",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "text-no-wrap text-truncate" },
                              [
                                _vm._v(
                                  " " + _vm._s(item.formationExperience) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                    ],
                    2
                  ),
                ]
              },
            },
            {
              key: "item.education",
              fn: function ({ item }) {
                return [
                  _c(
                    "AGTooltip",
                    { attrs: { "tooltip-text": item.education } },
                    [
                      [
                        _c(
                          "div",
                          { staticClass: "cbo-classification-education" },
                          [
                            _c(
                              "div",
                              { staticClass: "text-no-wrap text-truncate" },
                              [_vm._v(" " + _vm._s(item.education) + " ")]
                            ),
                          ]
                        ),
                      ],
                    ],
                    2
                  ),
                ]
              },
            },
            {
              key: "item.note",
              fn: function ({ item }) {
                return [
                  _c(
                    "AGTooltip",
                    { attrs: { "tooltip-text": item.note } },
                    [
                      [
                        _c("div", { staticClass: "cbo-classification-note" }, [
                          _c(
                            "div",
                            { staticClass: "text-no-wrap text-truncate" },
                            [_vm._v(" " + _vm._s(item.note) + " ")]
                          ),
                        ]),
                      ],
                    ],
                    2
                  ),
                ]
              },
            },
            {
              key: "item.id_column",
              fn: function ({ item }) {
                return [_vm._v(" " + _vm._s(item.id_column) + " ")]
              },
            },
            {
              key: "item.action",
              fn: function ({ item }) {
                return [
                  _c(
                    "span",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ag-btn-primary",
                          attrs: {
                            icon: "",
                            title: "Visualizar",
                            id: "Btn_visualizar",
                          },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "text-button",
                              attrs: { to: `/eSocial/rubricas/${item.id}` },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(" visibility "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm.loadingTable
            ? _c("template", { slot: "body" }, [
                _c(
                  "tr",
                  _vm._l(_vm.headers, function (loadItem) {
                    return _c(
                      "td",
                      [
                        _c("v-skeleton-loader", {
                          staticClass: "loaderCss",
                          attrs: { "max-width": loadItem.width },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }